import React from 'react';
import './Spinner.css'; // Import the CSS file for spinner styles

const Spinner = () => {
  return (
    <div className="spinner">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
