// TODO: A way to add Icons that don't already exist?
// - Register them and add
// - Include SVG Source/Inline?
// - By URL, or own component?

// What KINDS of toolbar buttons do we have...
// - One's that dispatch commands
// - One's that set tool's active
// - More custom, like CINE
//    - Built in for one's like this, or custom components?

// Visible?
// Disabled?
// Based on contexts or misc. criteria?
//  -- ACTIVE_ROUTE::VIEWER
//  -- ACTIVE_VIEWPORT::CORNERSTONE
// setToolActive commands should receive the button event that triggered
// so we can do the "bind to this button" magic
const url = new URL(document.location);

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
  BUILT_IN: 'builtIn',
};

const TOOLBAR_BUTTON_BEHAVIORS = {
  CINE: 'CINE',
  DOWNLOAD_SCREEN_SHOT: 'DOWNLOAD_SCREEN_SHOT',
  SYNC_VIEWPORTS: 'SYNC_VIEWPORTS',
};

/* TODO: Export enums through a extension manager. */
const enums = {
  TOOLBAR_BUTTON_TYPES,
  TOOLBAR_BUTTON_BEHAVIORS,
};

const StackScroll = {
  id: 'StackScroll',
  label: 'Stack Scroll',
  icon: 'sort',
  //
  type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  commandName: 'setToolActive',
  commandOptions: { toolName: 'StackScroll' },
};

const Zoom = {
  id: 'Zoom',
  label: 'Zoom',
  icon: 'search-plus',
  //
  type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  commandName: 'setToolActive',
  commandOptions: { toolName: 'Zoom' },
};

const Wwwc = {
  id: 'Wwwc',
  label: 'Levels',
  icon: 'level',
  //
  type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  commandName: 'setToolActive',
  commandOptions: { toolName: 'Wwwc' },
};

let favoritesMenu = {
  id: 'favoritesMenu',
  label: 'Predefined',
  icon: 'adjust',
  buttons: [],
};

// Default WW/WL favorites (note these do not match hotkey "presets" defined
// in platform/core/src/redux/reducers/preferences.js but based on
// values at https://radiopaedia.org/articles/windowing-ct).
//
let wwwlFavorites = [
  { description: 'Pulmón', window: '1500', level: '-600' },
  { description: 'Hígado', window: '150', level: '30' },
  { description: 'Hueso', window: '1800', level: '400' },
  { description: 'Cerebro', window: '80', level: '40' },
  { description: 'Mediastino', window: '350', level: '50' },
  { description: 'Columna', window: '250', level: '50' },
  { description: 'Abdomen', window: '400', level: '50' },
  { description: 'Cabeza', window: '375', level: '40' },
  { description: 'Subdural', window: '215', level: '75' },
  { description: 'Derrame', window: '8', level: '32' },
  { description: 'Temporales', window: '2800', level: '600' },
];

// Check if window configurations override defaults for favorites.
//
if (window && window.config && window.config.wwwlFavorites) {
  wwwlFavorites = window.config.wwwlFavorites;
}

for (var i = 0; i < wwwlFavorites.length; i++) {
  try {
    let wwwlFavorite = wwwlFavorites[i];
    favoritesMenu.buttons.push({
      id: 'Wwwl' + i,
      label: wwwlFavorite.description,
      icon: 'level',
      type: TOOLBAR_BUTTON_TYPES.COMMAND,
      commandName: 'setWindowLevel',
      commandOptions: {
        window: wwwlFavorite.window,
        level: wwwlFavorite.level,
      },
    });
  } catch (e) {
    console.warn(`Ignoring wwwlFavorite ${i}`);
  }
}

const Pan = {
  id: 'Pan',
  label: 'Pan',
  icon: 'arrows',
  //
  type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  commandName: 'setToolActive',
  commandOptions: { toolName: 'Pan' },
};

const LengthTool = {
  id: 'Length',
  label: 'Length',
  icon: 'measure-temp',
  //
  type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  commandName: 'setToolActive',
  commandOptions: { toolName: 'Length' },
};

const CtiTool = {
  id: 'CTI',
  label: 'CTI',
  icon: 'lung',
  //
  type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  commandName: 'setToolActive',
  commandOptions: { toolName: 'CTI' },
};

const Angle = {
  id: 'Angle',
  label: 'Angle',
  icon: 'angle-left',
  //
  type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  commandName: 'setToolActive',
  commandOptions: { toolName: 'Angle' },
};

const CobbAngle = {
  id: 'CobbAngle',
  label: 'Cobb Angle',
  icon: 'angle-open',
  //
  type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
  commandName: 'setToolActive',
  commandOptions: { toolName: 'CobbAngle' },
};

const Reset = {
  id: 'Reset',
  label: 'Reset',
  icon: 'reset',
  //
  type: TOOLBAR_BUTTON_TYPES.COMMAND,
  commandName: 'resetViewport',
};

const Cine = {
  id: 'Cine',
  label: 'CINE',
  icon: 'youtube',
  //
  type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
  options: {
    behavior: TOOLBAR_BUTTON_BEHAVIORS.CINE,
  },
};
const hideOverlay = {
  id: 'Overlay',
  label: 'Hide / Show',
  icon: 'eye',
  //
  type: TOOLBAR_BUTTON_TYPES.COMMAND,
  commandName: 'hideOverlay',
};

const pngViewer = {
  id: 'PNG',
  label: 'Get image',
  icon: 'create-screen-capture',
  //
  type: TOOLBAR_BUTTON_TYPES.COMMAND,
  commandName: 'pngViewer',
};

const pdfViewer = {
  id: 'PDF',
  label: 'Get PDF',
  icon: 'clipboard',
  //
  type: TOOLBAR_BUTTON_TYPES.COMMAND,
  commandName: 'pdfViewer',
};

const FullScreen = {
  id: 'FullScreen',
  label: 'Full screen',
  icon: 'full-screen',
  //
  type: TOOLBAR_BUTTON_TYPES.COMMAND,
  commandName: 'fullScreen',
};

const Exit2DMPR = {
  id: 'Exit2DMPR',
  label: 'Exit Rec.',
  icon: 'times',
  //
  type: TOOLBAR_BUTTON_TYPES.COMMAND,
  commandName: 'setCornerstoneLayout',
  context: 'ACTIVE_VIEWPORT::VTK',
};

const More = [
  /**
  {
    id: 'SyncViewports',
    label: 'Sync',
    icon: 'link-circles',
    //
    type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
    options: {
      behavior: TOOLBAR_BUTTON_BEHAVIORS.SYNC_VIEWPORTS,
    },
  },
  **/
  {
    id: 'Magnify',
    label: 'Magnify',
    icon: 'circle',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Magnify' },
  },
  {
    id: 'WwwcRegion',
    label: 'ROI Window',
    icon: 'stop',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'WwwcRegion' },
  },
  {
    id: 'DragProbe',
    label: 'Probe',
    icon: 'dot-circle',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'DragProbe' },
  },
  {
    id: 'EllipticalRoi',
    label: 'Ellipse',
    icon: 'circle-o',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'EllipticalRoi' },
  },
  {
    id: 'RectangleRoi',
    label: 'Rectangle',
    icon: 'square-o',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'RectangleRoi' },
  },
  {
    id: 'Invert',
    label: 'Invert',
    icon: 'adjust',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'invertViewport',
  },
  {
    id: 'RotateRight',
    label: 'Rotate Right',
    icon: 'rotate-right',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'rotateViewportCW',
  },
  {
    id: 'FlipH',
    label: 'Flip H',
    icon: 'arrows-alt-h',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'flipViewportHorizontal',
  },
  {
    id: 'FlipV',
    label: 'Flip V',
    icon: 'arrows-alt-v',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'flipViewportVertical',
  },
  {
    id: 'ArrowAnnotate',
    label: 'Annotate',
    icon: 'measure-non-target',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'ArrowAnnotate' },
  },
  /**
  {
    id: 'Clear',
    label: 'Clear',
    icon: 'trash',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'clearAnnotations',
  },
  */
  {
    id: 'Bidirectional',
    label: 'Bidirectional',
    icon: 'measure-target',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Bidirectional' },
  },
  CtiTool,
  /**
  {
    id: 'Download',
    label: 'Download',
    icon: 'create-screen-capture',
    //
    type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
    options: {
      behavior: TOOLBAR_BUTTON_BEHAVIORS.DOWNLOAD_SCREEN_SHOT,
      togglable: true,
    },
  },
  */
];

const SimpleTools = [StackScroll, Zoom, Wwwc, Pan, LengthTool, Angle];

if (url.searchParams.get('tools') === 'simple') {
  SimpleTools.push(pngViewer);
} else if (url.searchParams.get('tools') === 'mpr') {
  SimpleTools.push(pngViewer);
} else {
  More.push(pngViewer, pdfViewer);
}

const SimpleMobileTools = {
  id: 'MobileTools',
  label: 'Tools',
  icon: 'cog',
  buttons: SimpleTools,
};

let AdvanceTools = [
  StackScroll,
  Zoom,
  Wwwc,
  Pan,
  Cine,
  LengthTool,
  Angle,
  CobbAngle,
  hideOverlay,
  Reset,
];

if (!isOnMobile()) AdvanceTools.splice(3, 0, favoritesMenu);

const AdvanceMobileTools = {
  id: 'MobileTools',
  label: 'Tools',
  icon: 'cog',
  buttons: AdvanceTools,
};

let _definitions = [
  {
    id: 'More',
    label: 'More',
    icon: 'ellipse-circle',
    buttons: More,
  },
  Exit2DMPR,
];

if (!isOnMobile()) _definitions[0].buttons.push(FullScreen);

if (url.searchParams.get('tools') === 'simple') {
  _definitions = [Reset];
  if (isOnMobile()) {
    _definitions.unshift(SimpleMobileTools);
  } else {
    _definitions.unshift(...SimpleTools);
  }
} else if (url.searchParams.get('tools') === 'mpr') {
  _definitions = [Reset, Exit2DMPR];
  if (isOnMobile()) {
    _definitions.unshift(SimpleMobileTools);
  } else {
    _definitions.unshift(...SimpleTools);
  }
} else {
  if (isOnMobile()) {
    _definitions.unshift(AdvanceMobileTools);
  } else {
    _definitions.unshift(...AdvanceTools);
  }
}

const definitions = JSON.parse(JSON.stringify(_definitions));

export default {
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
};

function isOnMobile() {
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}
