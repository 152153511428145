import { MODULE_TYPES, utils } from '@ohif/core';

// TODO: Should probably use dcmjs for this
const SOP_CLASS_UIDS = {
  RAW: '1.2.840.10008.5.1.4.1.1.66',
  GRAYSCALE_SOFTCOPY_PS: '1.2.840.10008.5.1.4.1.1.11.1',
  COLOR_SOFTCOPY_PS: '1.2.840.10008.5.1.4.1.1.11.2',
  PSUEDOCOLOR_SOFTCOPY_PS: '1.2.840.10008.5.1.4.1.1.11.3',
  BLENDING_SOFTCOPY_PS: '1.2.840.10008.5.1.4.1.1.11.4',
};

const OHIFDicomRawSopClassHandler = {
  id: 'OHIFDicomRawSopClassHandler',
  type: MODULE_TYPES.SOP_CLASS_HANDLER,
  sopClassUIDs: Object.values(SOP_CLASS_UIDS),
  getDisplaySetFromSeries(series, study, dicomWebClient, authorizationHeaders) {
    let instance = series.getFirstInstance();
    const metadata = instance.getData().metadata;
    const {
      Modality,
    } = metadata;

    let seriesDescription = instance.getTagValue('SeriesDescription')
    if ((seriesDescription == null) || (seriesDescription == '')) {
      seriesDescription = "Non-image data"
    }
    let seriesNumber = 0
    try {
      seriesNumber = parseInt(instance.getTagValue('SeriesNumber'), 10)
    }
    catch (err) {
      console.warn("Unable to parse series number")
      seriesNumber = 0
    }

    return {
      plugin: 'raw',
      Modality: Modality,
      displaySetInstanceUID: utils.guid(),
      wadoRoot: study.getData().wadoRoot,
      wadoUri: instance.getData().wadouri,
      SOPInstanceUID: instance.getSOPInstanceUID(),
      SeriesInstanceUID: series.getSeriesInstanceUID(),
      StudyInstanceUID: study.getStudyInstanceUID(),
      SeriesNumber: seriesNumber,
      SeriesDescription: seriesDescription,
      authorizationHeaders: authorizationHeaders,
    };
  },
};

export default OHIFDicomRawSopClassHandler;
