const defaultState = {
  windowLevelData: {
    1: { description: 'Pulmón', window: '1500', level: '-600' },
    2: { description: 'Hígado', window: '150', level: '30' },
    3: { description: 'Hueso', window: '1800', level: '400' },
    4: { description: 'Cerebro', window: '80', level: '40' },
    5: { description: 'Mediastino', window: '350', level: '50' },
    6: { description: 'Columna - tejidos blandos', window: '250', level: '50' },
    7: { description: 'Abdomen - tejidos blandos', window: '400', level: '50' },
    8: { description: 'Cabeza - tejidos blandos', window: '375', level: '40' },
    9: { description: 'Cabeza - subdural', window: '215', level: '75' },
    10: { description: 'Cabeza - derrame', window: '8', level: '32' },
    11: { description: 'Cabeza - temporales', window: '2800', level: '600' },
  },
  generalPreferences: {
    language: 'es',
  },
};

const preferences = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_USER_PREFERENCES': {
      return Object.assign({}, state, action.state);
    }
    default:
      return state;
  }
};

export { defaultState };
export default preferences;
