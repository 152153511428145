import React, { useEffect, useCallback } from 'react';
import { View2D } from 'react-vtkjs-viewport';
import PropTypes from 'prop-types';
import Hammer from 'hammerjs'

import './VTKViewport.css';

const VTKViewport = props => {
  const style = { width: '100%', height: '100%', position: 'relative' };

  const viewportId = "vtk-viewport-" + props.viewportIndex;

  const setViewportActiveHandler = useCallback(() => {
    const { setViewportActive, viewportIndex, activeViewportIndex } = props;

    if (viewportIndex !== activeViewportIndex) {
      // set in Connected
      setViewportActive();
    }
  });

  useEffect(() => {
    const handleScrollEvent = evt => {
      const vtkViewportApiReference = props.onScroll(props.viewportIndex) || {};
      const viewportUID = vtkViewportApiReference.uid;
      const viewportWasScrolled = viewportUID === evt.detail.uid;

      if (viewportWasScrolled) {
        setViewportActiveHandler();
      }
    };

    window.addEventListener('vtkscrollevent', handleScrollEvent);

    const viewportId = "vtk-viewport-" + props.viewportIndex;
    const element = document.getElementById(viewportId);
    const handleDoubleTapEvent = () => {
      // A double-click should toggle normal and fullscreen modes.
      //
      if (element) {
        if ((typeof document.fullscreenElement !== "undefined") && (document.fullscreenElement != null)) {
          document.exitFullscreen();
        }
        else if ((typeof document.webkitFullscreenElement !== "undefined") && (document.webkitFullscreenElement != null)) {
          document.webkitExitFullscreen();
        }
        else if (element.requestFullscreen) {
          element.requestFullscreen();
        }
        else if (element.webkitRequestFullscreen) { /* Safari */
          element.webkitRequestFullscreen();
        }
        else {
          console.log('This browser does not support fullscreen mode.')
        }
      }
    };

    Hammer(element).on('doubletap', handleDoubleTapEvent)

    return () => {
      window.removeEventListener('vtkscrollevent', handleScrollEvent);
      Hammer(element).off('doubletap');
    }
  }, [props, props.onScroll, props.viewportIndex, setViewportActiveHandler]);

  return (
    <div
      id={viewportId}
      className="vtk-viewport-handler"
      style={style}
      onClick={setViewportActiveHandler}
    >
      <View2D {...props} />
    </div>
  );
};

VTKViewport.propTypes = {
  setViewportActive: PropTypes.func.isRequired,
  viewportIndex: PropTypes.number.isRequired,
  activeViewportIndex: PropTypes.number.isRequired,
  /* Receives viewportIndex */
  onScroll: PropTypes.func,
};

VTKViewport.defaultProps = {
  onScroll: () => { },
};

export default VTKViewport;
