import React from 'react';
import OHIFDicomRawSopClassHandler from './OHIFDicomRawSopClassHandler.js';

const Component = React.lazy(() => {
  return import('./OHIFDicomRawViewport');
});

const OHIFDicomRawViewport = props => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </React.Suspense>
  );
};

export default {
  id: 'raw',
  getViewportModule() {
    return OHIFDicomRawViewport;
  },
  getSopClassHandlerModule() {
    return OHIFDicomRawSopClassHandler;
  },
};
