const state = {
  enabledElements: {},
  syncPoint: {
    frameOfReferenceUID: '_NoT_VaLiD_',
    patientPoint: { x: 0, y: 0, z: 0 },
  },
  syncViewports: true,
};

/**
 * Sets the enabled element `dom` reference for an active viewport.
 * @param {HTMLElement} dom Active viewport element.
 * @return void
 */
const setEnabledElement = (viewportIndex, element) =>
  (state.enabledElements[viewportIndex] = element);

/**
 * Grabs the enabled element `dom` reference of an active viewport.
 *
 * @return {HTMLElement} Active viewport element.
 */
const getEnabledElement = viewportIndex => state.enabledElements[viewportIndex];

const setSyncPoint = (syncPoint) =>
  (state.syncPoint = syncPoint);

const getSyncPoint = () => state.syncPoint;

const setSyncViewports = (syncViewports) =>
  (state.syncViewports = syncViewports);

const getSyncViewports = () => state.syncViewports;

export {
  setEnabledElement,
  getEnabledElement,
  setSyncPoint,
  getSyncPoint,
  setSyncViewports,
  getSyncViewports
};
